import { useState, useCallback, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import { useTheme } from 'next-themes';
import { useDropzone } from 'react-dropzone';
import { 
  DocumentIcon, 
  BoltIcon, 
  SparklesIcon,
  ShieldCheckIcon,
  DocumentMagnifyingGlassIcon,
  DocumentCheckIcon,
  CodeBracketIcon,
  CheckIcon,
  ChevronUpIcon
} from '@heroicons/react/24/outline';
import { Disclosure, Dialog } from '@headlessui/react';
import { tiers, getFAQs, getTierData } from '../constants';
import { useLocation, useNavigate } from 'react-router-dom';
import CTA from '../components/CTA';
import { useTranslation } from 'react-i18next';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

// Helper function for class names
const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

const DocumentComparison = () => {
  const { t } = useTranslation('home');
  const [transactions, setTransactions] = useState([
    { date: '10/01', desc: 'Walmart', amount: -150 },
    { date: '10/03', desc: 'ATM Withdrawal', amount: -300 },
    { date: '10/03', desc: 'Debt Collection', amount: -900 },
    { date: '10/16', desc: 'Welfare Benefits', amount: 1250 },
    { date: '10/20', desc: 'Amazon.com', amount: -750 },
    { date: '10/21', desc: 'Kroger', amount: -250 },
    { date: '10/28', desc: 'Shell Oil', amount: -60 }
  ]);
  const [balance, setBalance] = useState(240);
  const [isEditing, setIsEditing] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [modificationMessage, setModificationMessage] = useState(null);
  const [highlightedRow, setHighlightedRow] = useState(null);
  const [showSavedMessage, setShowSavedMessage] = useState(false);
  const [isDocumentVisible, setIsDocumentVisible] = useState(true);

  // Animation sequence control
  useEffect(() => {
    const startAnimation = (entries) => {
      if (entries[0].isIntersecting && !isEditing) {
        setIsEditing(true);
      }
    };

    const observer = new IntersectionObserver(startAnimation, {
      threshold: 0.5,
    });

    const element = document.getElementById('document-comparison');
    if (element) observer.observe(element);

    return () => observer.disconnect();
  }, [isEditing]);

  // Show modification message with animation
  const showModification = (message) => {
    setModificationMessage(message);
    setTimeout(() => setModificationMessage(null), 3000);
  };

  // Animation steps sequence
  useEffect(() => {
    if (!isEditing) return;

    const steps = [
      // Step 1: Change both description and amount for Welfare Benefits
      () => {
        setHighlightedRow('Welfare Benefits');
        setTimeout(() => {
          setTransactions(prev => prev.map(t => 
            t.desc === 'Welfare Benefits' 
              ? { ...t, desc: 'Salary Payment', amount: 7500 }
              : t
          ));
          showModification(t('pdfeditor.modifying'));
        }, 1000);
      },
      // Step 2: Change both description and amount for Debt Collection
      () => {
        setHighlightedRow('Debt Collection');
        setTimeout(() => {
          setTransactions(prev => prev.map(t => 
            t.desc === 'Debt Collection' 
              ? { ...t, desc: 'Charity Donation', amount: -100 }
              : t
          ));
          showModification(t('pdfeditor.modifying'));
        }, 1000);
      },
      // Step 3: Change balance to 24,000
      () => {
        setHighlightedRow('balance');
        setTimeout(() => {
          setBalance(24000);
          showModification(t('pdfeditor.balanceModified'));
          
          // Show saved message after a longer delay
          setTimeout(() => {
            setShowSavedMessage(true);
            
            // Hide saved message and start document transition
            setTimeout(() => {
              setShowSavedMessage(false);
              
              // Fade out the document
              setIsDocumentVisible(false);
              
              // Wait for fade out, then reset and show document
              setTimeout(() => {
                // Reset to original values
                setTransactions([
                  { date: '10/01', desc: 'Walmart', amount: -150 },
                  { date: '10/03', desc: 'ATM Withdrawal', amount: -300 },
                  { date: '10/03', desc: 'Debt Collection', amount: -900 },
                  { date: '10/16', desc: 'Welfare Benefits', amount: 1250 },
                  { date: '10/20', desc: 'Amazon.com', amount: -750 },
                  { date: '10/21', desc: 'Kroger', amount: -250 },
                  { date: '10/28', desc: 'Shell Oil', amount: -60 }
                ]);
                setBalance(240);
                setCurrentStep(0);
                
                // Show document again
                setTimeout(() => {
                  setIsDocumentVisible(true);
                  setModificationMessage(null);
                }, 1000);
              }, 2000);
            }, 3000);
          }, 3000);
        }, 1000);
      }
    ];

    if (currentStep < steps.length) {
      const timer = setTimeout(() => {
        steps[currentStep]();
        // Clear highlight after 4 seconds
        setTimeout(() => setHighlightedRow(null), 4000);
        setCurrentStep(prev => prev + 1);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [isEditing, currentStep, t]);

  // Update the transaction row rendering to include highlighting
  const getRowClassName = (transaction) => {
    const isHighlighted = 
      highlightedRow === transaction.desc || 
      (transaction.desc === 'Salary Payment' && highlightedRow === 'Welfare Benefits') ||
      (transaction.desc === 'Charity Donation' && highlightedRow === 'Debt Collection');
    
    return `grid grid-cols-[45px_1fr_80px] sm:grid-cols-[60px_1fr_100px] gap-2 p-2 rounded transition-colors duration-300
      ${isHighlighted ? 'bg-yellow-100 dark:bg-yellow-900/30' : ''}`;
  };

  // Update the balance display to include highlighting
  const getBalanceClassName = () => {
    return `px-4 py-3 rounded-lg flex justify-between transition-colors duration-300
      ${highlightedRow === 'balance' ? 'bg-yellow-100 dark:bg-yellow-900/30' : 'bg-gray-100 dark:bg-gray-700'}`;
  };

  return (
    <div id="document-comparison" className="relative w-full max-w-3xl mx-auto">
      {/* PDF Editor Window Frame */}
      <div className="bg-gray-100 dark:bg-gray-900 rounded-xl shadow-2xl overflow-hidden border border-gray-200 dark:border-gray-700">
        {/* Window Title Bar */}
        <div className="bg-gray-200 dark:bg-gray-800 px-3 sm:px-4 py-1.5 sm:py-2 flex items-center justify-between border-b border-gray-300 dark:border-gray-600">
          {/* Window Controls */}
          <div className="flex items-center space-x-1.5 sm:space-x-2">
            <div className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-red-500"></div>
            <div className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-yellow-500"></div>
            <div className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-green-500"></div>
          </div>
          
          {/* File Name */}
          <div className="flex items-center space-x-1.5 sm:space-x-2 text-xs sm:text-sm text-gray-600 dark:text-gray-400">
            <svg className="w-3 h-3 sm:w-4 sm:h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 22H17C18.6569 22 20 20.6569 20 19V9L15 4H7C5.34315 4 4 5.34315 4 7V19C4 20.6569 5.34315 22 7 22Z" stroke="currentColor" strokeWidth="2"/>
              <path d="M14 4V10H20" stroke="currentColor" strokeWidth="2"/>
            </svg>
            <span>{t('pdfeditor.title')}</span>
          </div>
          
          <div className="w-4 sm:w-16"></div> {/* Spacer for alignment */}
        </div>

        {/* Toolbar */}
        <div className="bg-gray-50 dark:bg-gray-800/50 px-2 sm:px-4 py-1 sm:py-1.5 border-b border-gray-200 dark:border-gray-700 flex items-center space-x-2 sm:space-x-4">
          <button className="p-1 sm:p-1.5 hover:bg-gray-200 dark:hover:bg-gray-700 rounded">
            <svg className="w-3 h-3 sm:w-4 sm:h-4 text-gray-600 dark:text-gray-400" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          </button>
          <button className="p-1 sm:p-1.5 hover:bg-gray-200 dark:hover:bg-gray-700 rounded">
            <svg className="w-3 h-3 sm:w-4 sm:h-4 text-gray-600 dark:text-gray-400" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 12H4" />
            </svg>
          </button>
          <button className="p-1 sm:p-1.5 hover:bg-gray-200 dark:hover:bg-gray-700 rounded">
            <svg className="w-3 h-3 sm:w-4 sm:h-4 text-gray-600 dark:text-gray-400" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
            </svg>
          </button>
          <div className="h-3 sm:h-4 w-px bg-gray-300 dark:bg-gray-600"></div>
          <span className="text-xs sm:text-sm text-gray-600 dark:text-gray-400">{t('pdfeditor.pageCount')}</span>
        </div>

        {/* Document Viewer Area */}
        <div className="bg-gray-300 dark:bg-gray-950 p-4 sm:p-8 min-h-[400px] sm:min-h-[600px] overflow-auto relative">
          {/* Document Page with fade transition */}
          <motion.div
            animate={{ opacity: isDocumentVisible ? 1 : 0 }}
            transition={{ duration: 0.5 }}
            className="bg-white dark:bg-gray-800 w-full max-w-2xl mx-auto rounded-lg shadow-lg p-4 sm:p-8"
          >
            {/* Bank Statement Header */}
            <div className="mb-4 sm:mb-8">
              <div className="flex items-center space-x-2 sm:space-x-3 mb-3 sm:mb-4">
                <svg className="w-6 h-6 sm:w-8 sm:h-8 text-gray-700 dark:text-gray-300" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M11.5,1L2,6V8H21V6M16,10V17H19V10M2,22H21V19H2M10,10V17H13V10M4,10V17H7V10H4Z" />
                </svg>
                <div>
                  <h3 className="text-lg sm:text-2xl font-bold text-gray-900 dark:text-white">{t('pdfeditor.bankStatement')}</h3>
                  <p className="text-xs sm:text-sm text-gray-600 dark:text-gray-400">Global Bank Corporation</p>
                </div>
              </div>

              {/* Statement Info Grid */}
              <div className="grid grid-cols-2 gap-3 sm:gap-6 text-xs sm:text-sm">
                <div className="space-y-1">
                  <div className="text-gray-600 dark:text-gray-400">Account Holder:</div>
                  <div className="font-medium text-gray-900 dark:text-white">John Doe</div>
                </div>
                <div className="space-y-1">
                  <div className="text-gray-600 dark:text-gray-400">Account Number:</div>
                  <div className="font-medium text-gray-900 dark:text-white">****7890</div>
                </div>

              </div>

              {/* Divider */}
              <div className="h-px bg-gray-200 dark:bg-gray-700 my-4"></div>
            </div>

            {/* Modification Message - Repositioned */}
            <AnimatePresence>
              {modificationMessage && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                  className="absolute left-1/2 bottom-24 transform -translate-x-1/2 z-50"
                >
                  <div className="bg-yellow-50 dark:bg-yellow-900/50 border border-yellow-200 dark:border-yellow-800 rounded-lg px-4 py-2 shadow-lg">
                    <div className="flex items-center space-x-2">
                      <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                      </svg>
                      <span className="text-sm font-medium text-yellow-800 dark:text-yellow-200">
                        {modificationMessage}
                      </span>
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

            {/* Saved Message - Repositioned */}
            <AnimatePresence>
              {showSavedMessage && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                  className="absolute left-1/2 bottom-24 transform -translate-x-1/2 z-50"
                >
                  <div className="bg-green-100 dark:bg-green-900/50 border border-green-200 dark:border-green-800 rounded-lg px-4 py-2 shadow-lg">
                    <div className="flex items-center space-x-2">
                      <CheckIcon className="h-5 w-5 text-green-600 dark:text-green-400" />
                      <span className="text-sm font-medium text-green-800 dark:text-green-200">
                        {t('pdfeditor.saving')}
                      </span>
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

            {/* Balance display with highlight animation */}
            <div className={getBalanceClassName()}>
              <span className="text-gray-700 dark:text-gray-300">Current Balance:</span>
              <motion.span 
                className="font-mono font-medium text-gray-900 dark:text-white"
                key={balance}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                ${balance.toLocaleString()}
              </motion.span>
            </div>

            {/* Transactions with highlight animations */}
            <div className="space-y-2">
              {/* Headers */}
              <div className="grid grid-cols-[45px_1fr_80px] sm:grid-cols-[60px_1fr_100px] gap-2 p-2 border-b border-gray-200 dark:border-gray-700">
                <span className="text-gray-500 dark:text-gray-400 font-mono text-xs sm:text-sm font-semibold">Date</span>
                <span className="text-gray-500 dark:text-gray-400 font-mono text-xs sm:text-sm font-semibold">Description</span>
                <span className="text-gray-500 dark:text-gray-400 font-mono text-xs sm:text-sm font-semibold text-right">Amount</span>
              </div>
              
              {transactions.map((transaction, index) => (
                <div key={`${transaction.desc}-${transaction.amount}`} className={getRowClassName(transaction)}>
                  <span className="text-gray-600 dark:text-gray-400 font-mono text-xs sm:text-sm">
                    {transaction.date}
                  </span>
                  <motion.span 
                    className="text-gray-900 dark:text-white font-mono text-xs sm:text-sm truncate"
                    key={transaction.desc}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    {transaction.desc}
                  </motion.span>
                  <motion.span 
                    className={`text-right font-mono text-xs sm:text-sm ${
                      transaction.amount > 0 
                        ? 'text-green-600 dark:text-green-400'
                        : 'text-gray-600 dark:text-gray-400'
                    }`}
                    key={transaction.amount}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    {transaction.amount > 0 ? '+' : ''}{transaction.amount.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </motion.span>
                </div>
              ))}
            </div>

            {/* Footer */}
            <div className="mt-6 pt-4 border-t border-gray-200 dark:border-gray-700 text-sm text-gray-500 dark:text-gray-400">
              <div>Statement Period: 10/01/2024 - 10/31/2024</div>

            </div>
          </motion.div>

          {/* Loading message when document is hidden */}
          <AnimatePresence>
            {!isDocumentVisible && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="absolute inset-0 flex items-center justify-center"
              >
                <div className="flex items-center space-x-3 bg-white dark:bg-gray-800 rounded-lg px-4 py-2 shadow-lg">
                  <svg className="animate-spin h-5 w-5 text-primary-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  <span className="text-gray-900 dark:text-white font-medium">
                    {t('pdfeditor.loading')}
                  </span>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

const LOGO_NAMES = [
  '4bimmo', 'adecco', 'amazon', 'bajajfinserv', 'centralpay',
  'cloudtalk', 'curelink', 'digiverifier', 'ethena', 'ey',
  'hartehanks', 'inmoprocesos', 'kerala', 'kinetix', 'montium',
  'naver', 'navi', 'nbx', 'networkfunding', 'paypro',
  'priya', 'quantum', 'recsol', 'speedtrader', 'tic',
  'vericasa', 'wns'
];

const ErrorModal = ({ title, message, onClose }) => {
  return (
    <Dialog open={true} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-gray-500/75 transition-opacity" aria-hidden="true" />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="bg-white dark:bg-gray-800 px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 dark:bg-red-900/30 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationTriangleIcon className="h-6 w-6 text-red-600 dark:text-red-400" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                    {title}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      {message}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 dark:bg-gray-700/50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                type="button"
                onClick={onClose}
                className="inline-flex w-full justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 sm:ml-3 sm:w-auto dark:bg-primary-500 dark:hover:bg-primary-400"
              >
                Close
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

function Home() {
  const { theme, setTheme } = useTheme();
  const [file, setFile] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);
  const location = useLocation();
  const { t } = useTranslation('home');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alert, setAlert] = useState(null);
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  // Move personalDomains inside the component
  const personalDomains = [
    'gmail.com',
    'yahoo.com', 
    'hotmail.com',
    'outlook.com',
    'aol.com',
    'protonmail.com',
    'icloud.com',
    'mail.com',
    'yandex.com',
    'yandex.ru',
    'mail.ru',
    'qq.com',
    'onmicrosoft.com',
    'foxmail.com',
    'zoho.com',
    'gmx.com',
    'gmx.net',
    'live.com',
    'msn.com',
    'inbox.com',
    'fastmail.com',
    'me.com',
    'yahoo.co.uk',
    'yahoo.co.jp',
    'yahoo.fr',
    'yahoo.de',
    'yahoo.it',
    'rediffmail.com',
    'free.fr',
    'web.de',
    'libero.it',
    'rambler.ru',
    'naver.com',
    'daum.net',
    'seznam.cz',
    'op.pl',
    'interia.pl',
    'hanmail.net',
    'sina.com',
    'mailinator.com',
    'tempmail.com'
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
      const progress = Math.min(window.scrollY / 100, 1);
      setScrollProgress(progress);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    // Check if we have a scrollTo target in the navigation state
    if (location.state?.scrollTo === 'pricing') {
      // Clear the state so it doesn't scroll again on refresh
      window.history.replaceState({}, document.title);
      // Scroll to pricing section with a slight delay to ensure content is rendered
      setTimeout(() => {
        document.getElementById('pricing')?.scrollIntoView({ 
          behavior: 'smooth',
          block: 'start'
        });
      }, 100);
    }
  }, [location]);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles?.[0]) {
      setFile(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf']
    },
    maxFiles: 1
  });

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  // Keep the useState for selected logos
  const [selectedLogos] = useState(() => 
    [...LOGO_NAMES]
      .sort(() => Math.random() - 0.5)
      .slice(0, 8)
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Add this helper function inside the Home component
  const isValidEmailFormat = (email) => {
    // Strict email validation that prevents trailing dots
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return emailRegex.test(email);
  };

  // Add function to clean email aliases
  const cleanEmailAlias = (email) => {
    const [localPart, domain] = email.split('@');
    const cleanedLocalPart = localPart.split('+')[0];
    return `${cleanedLocalPart}@${domain}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate required fields
    if (!email || !file || !termsAccepted) {
      setAlert({
        title: t('errors.missingInfo.title'),
        message: t('errors.missingInfo.message')
      });
      return;
    }

    // Clean the email by removing aliases
    const cleanedEmail = cleanEmailAlias(email);
    
    // Check if personal email
    const domain = cleanedEmail.split('@')[1]?.toLowerCase();
    if (domain && personalDomains.includes(domain)) {
      setAlert({
        title: t('errors.businessEmail.title'),
        message: t('errors.businessEmail.message')
      });
      return;
    }
    
    setIsSubmitting(true);

    try {
      const formData = new FormData();
      formData.append('email', cleanedEmail);
      formData.append('language', i18n.language);
      formData.append('file', file);

      const response = await fetch('https://api.verifypdf.com/free_pdf_document', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        navigate('/verifypdf-free-document-check-sent');
      } else {
        const errorMessages = {
          429: {
            title: t('errors.rateLimit.title'),
            message: t('errors.rateLimit.message')
          },
          409: {
            title: t('errors.emailUsed.title'),
            message: t('errors.emailUsed.message')
          },
          400: {
            title: t('errors.invalidFile.title'),
            message: t('errors.invalidFile.message')
          },
          403: {
            title: t('errors.invalidEmail.title'),
            message: t('errors.invalidEmail.message')
          }
        };

        setAlert(
          errorMessages[response.status] || {
            title: t('errors.submission.title'),
            message: t('errors.submission.message')
          }
        );
      }
    } catch (error) {
      console.error('Submission error:', error);
      setAlert({
        title: t('errors.connection.title'),
        message: t('errors.connection.message')
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Sync URL with language on mount and language changes
  useEffect(() => {
    const urlLang = location.pathname.split('/')[1];
    const supportedLangs = ['es', 'fr', 'de', 'nl'];
    
    if (urlLang && supportedLangs.includes(urlLang)) {
      // If URL has language prefix, update i18n
      if (urlLang !== i18n.language) {
        i18n.changeLanguage(urlLang);
      }
    } else if (location.pathname === '/' && i18n.language !== 'en') {
      // If we're at root but language isn't English, navigate to language URL
      navigate(`/${i18n.language}`);
    } else if (supportedLangs.includes(i18n.language) && location.pathname === '/') {
      // If language is supported but we're at root, navigate to language URL
      navigate(`/${i18n.language}`);
    }
  }, [i18n.language, location.pathname, navigate]);

  return (
    <>
      <div className="min-h-screen bg-white dark:bg-gray-900 transition-colors duration-200">
        <Helmet>
          <title>VerifyPDF - Detect fake documents in seconds</title>
          <meta name="description" content="Verify the authenticity of PDF documents instantly using AI-powered document forensics. Detect modifications and prevent fraud." />
        </Helmet>

        {/* Hero Section */}
        <motion.section 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="relative pt-28 pb-32 px-4 sm:px-6 lg:px-8 overflow-hidden isolate"
        >
          {/* Base gradient */}
          <div 
            className="absolute inset-0 -z-50" 
            aria-hidden="true"
          >
            <div className="absolute inset-0 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] dark:bg-[radial-gradient(45rem_50rem_at_top,theme(colors.primary.900),theme(colors.gray.900))] opacity-20" />
          </div>
          
          {/* Diagonal line - more subtle blue tint for light mode */}
          <div 
            className="absolute inset-y-0 right-1/2 -z-40 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-indigo-50/90 dark:bg-gray-900/80 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center"
            style={{
              maskImage: 'linear-gradient(to bottom, black 50%, transparent)',
              WebkitMaskImage: 'linear-gradient(to bottom, black 50%, transparent)',
            }}
          />

          <div className="relative z-10 max-w-7xl mx-auto text-center">
            <motion.h2 
              className="font-['Domine'] text-4xl md:text-6xl font-bold text-gray-900 dark:text-white mb-8 mt-8 sm:mt-12"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              {t('hero.title')} <br />
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 via-primary-500 to-indigo-500 dark:from-blue-400 dark:via-primary-400 dark:to-indigo-400">
                {t('hero.subtitle')}
              </span>
            </motion.h2>

            <motion.p
              className="font-['Nunito'] mt-4 text-lg text-gray-600 dark:text-gray-300 max-w-3xl mx-auto"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.4 }}
            >
              {t('hero.description')}
            </motion.p>

            {/* Upload Section */}
            <motion.div
              className="mt-12 max-w-xl mx-auto"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6 }}
            >
              <div className="flex flex-col space-y-4">
                <div {...getRootProps()} className="relative">
                  <input {...getInputProps()} />
                  <motion.div
                    className={`w-full min-h-[160px] flex flex-col items-center justify-center px-4 py-6 rounded-lg border-2 border-dashed transition-colors cursor-pointer
                      ${isDragActive 
                        ? 'border-primary-500 bg-primary-50 dark:bg-primary-900/10' 
                        : 'border-gray-300 dark:border-gray-700 hover:border-primary-500 dark:hover:border-primary-500'
                      }`}
                    whileHover={{ scale: 1.01 }}
                    whileTap={{ scale: 0.99 }}
                  >
                    <DocumentIcon className="w-12 h-12 text-gray-400 dark:text-gray-500 mb-4" />
                    <AnimatePresence>
                      {file ? (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          className="text-center"
                        >
                          <p className="text-sm font-medium text-primary-600 dark:text-primary-400">
                            {file.name}
                          </p>
                          <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                            {(file.size / 1024 / 1024).toFixed(2)} MB
                          </p>
                        </motion.div>
                      ) : (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          className="text-center"
                        >
                          <p className="text-gray-600 dark:text-gray-300 font-medium">
                            {isDragActive ? t('upload.dragActive') : t('upload.dragDrop')}
                          </p>
                          <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                            {t('upload.browse')}
                          </p>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </motion.div>
                </div>

                <input
                  type="email"
                  placeholder={t('upload.emailPlaceholder')}
                  onChange={handleEmailChange}
                  className="px-4 py-3 rounded-lg border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-900 dark:text-white focus:ring-2 focus:ring-primary-500 focus:border-transparent outline-none"
                />

                {/* Terms checkbox - centered */}
                <div className="flex items-center justify-center space-x-2">
                  <input
                    type="checkbox"
                    id="terms"
                    checked={termsAccepted}
                    onChange={(e) => setTermsAccepted(e.target.checked)}
                    className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
                  />
                  <label htmlFor="terms" className="text-sm text-gray-600 dark:text-gray-300">
                    {t('upload.terms')}
                    <a
                      href="/terms"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary-600 dark:text-primary-400 hover:underline"
                    >
                      {t('upload.termsLink')}
                    </a>
                    {' '}and{' '}
                    <a
                      href="/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary-600 dark:text-primary-400 hover:underline"
                    >
                      {t('upload.privacy')}
                    </a>
                  </label>
                </div>

                <div className="flex justify-center">
                  <button 
                    onClick={handleSubmit}
                    disabled={!isValidEmailFormat(email) || !termsAccepted || !file || isSubmitting}
                    className={`px-8 py-3 rounded-lg text-sm font-semibold transition-colors duration-200
                      ${isValidEmailFormat(email) && termsAccepted && file && !isSubmitting
                        ? 'bg-primary-600 hover:bg-primary-700 text-white'
                        : 'bg-gray-100 dark:bg-gray-800 text-gray-400 dark:text-gray-600 cursor-not-allowed'
                      }`}
                  >
                    {isSubmitting ? (
                      <div className="flex items-center justify-center">
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        {t('upload.submitting')}
                      </div>
                    ) : (
                      t('upload.button')
                    )}
                  </button>
                </div>
              </div>
            </motion.div>
          </div>
        </motion.section>

        {/* Trusted By Section - Smooth Mobile Animation */}
        <section className="bg-white dark:bg-gray-900 py-8 sm:py-12">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <h2 className="font-['Domine'] text-center text-4xl font-semibold text-gray-900 dark:text-white sm:text-5xl mb-12">
              {t('trusted.title')}
            </h2>
            
            <div className="relative">
              {/* Gradient Overlays */}
              <div className="absolute left-0 top-0 bottom-0 w-20 bg-gradient-to-r from-white dark:from-gray-900 to-transparent z-10" />
              <div className="absolute right-0 top-0 bottom-0 w-20 bg-gradient-to-l from-white dark:from-gray-900 to-transparent z-10" />
              
              {/* Logo Container */}
              <div className="overflow-hidden">
                <div className="flex">
                  <motion.div
                    className="flex gap-12 md:gap-16 items-center"
                    animate={{
                      x: ["0%", "-50%"]
                    }}
                    transition={{
                      duration: viewportWidth < 768 ? 40 : 120,
                      ease: "linear",
                      repeat: Infinity,
                      repeatType: "loop"
                    }}
                  >
                    {/* Double the selected logos */}
                    {[...selectedLogos, ...selectedLogos, ...selectedLogos, ...selectedLogos].map((name, index) => (
                      <div 
                        key={`${name}-${index}`} 
                        className="flex-shrink-0 w-28 md:w-40 h-20 flex items-center justify-center"
                      >
                        <img
                          src={`/logos/light/${name}.png`}
                          className="max-h-8 md:max-h-12 w-auto object-contain opacity-70 grayscale hover:grayscale-0 hover:opacity-100 transition-all duration-300 dark:hidden"
                          alt={name}
                        />
                        <img
                          src={`/logos/dark/${name}-white.png`}
                          className="hidden max-h-8 md:max-h-12 w-auto object-contain opacity-50 grayscale hover:grayscale-0 hover:opacity-100 transition-all duration-300 dark:block"
                          alt={name}
                        />
                      </div>
                    ))}
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Value Proposition Section */}
        <section className="py-16 bg-white dark:bg-gray-900 relative overflow-hidden">
          {/* Decorative blurred elements */}
          <div aria-hidden="true" className="absolute inset-0 -z-10 overflow-hidden">
            <div className="absolute left-[calc(50%-20rem)] aspect-[1155/678] w-[40rem] -translate-x-1/2 bg-gradient-to-tr from-primary-300 to-primary-400 opacity-20 dark:opacity-30 blur-3xl">
              <div
                style={{
                  clipPath:
                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                }}
              />
            </div>
            <div className="absolute right-[calc(50%-20rem)] aspect-[1155/678] w-[40rem] translate-x-1/2 bg-gradient-to-tr from-primary-400 to-primary-500 opacity-20 dark:opacity-30 blur-3xl">
              <div
                style={{
                  clipPath:
                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                }}
              />
            </div>
          </div>

          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 gap-12 md:grid-cols-3">
              {[
                {
                  title: t('valueProps.reduce.title'),
                  description: t('valueProps.reduce.description'),
                  icon: ShieldCheckIcon,
                  delay: 0.1,
                  gradient: "from-blue-500/20 to-cyan-500/20 dark:from-blue-500/10 dark:to-cyan-500/10",
                  iconBg: "bg-gradient-to-br from-blue-100 to-cyan-100 dark:from-blue-900/30 dark:to-cyan-900/30"
                },
                {
                  title: t('valueProps.boost.title'),
                  description: t('valueProps.boost.description'),
                  icon: BoltIcon,
                  delay: 0.2,
                  gradient: "from-amber-500/20 to-orange-500/20 dark:from-amber-500/10 dark:to-orange-500/10",
                  iconBg: "bg-gradient-to-br from-amber-100 to-orange-100 dark:from-amber-900/30 dark:to-orange-900/30"
                },
                {
                  title: t('valueProps.better.title'),
                  description: t('valueProps.better.description'),
                  icon: SparklesIcon,
                  delay: 0.3,
                  gradient: "from-emerald-500/20 to-teal-500/20 dark:from-emerald-500/10 dark:to-teal-500/10",
                  iconBg: "bg-gradient-to-br from-emerald-100 to-teal-100 dark:from-emerald-900/30 dark:to-teal-900/30"
                }
              ].map((feature, index) => (
                <motion.div
                  key={feature.title}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: feature.delay }}
                  className="relative group"
                >
                  <motion.div
                    whileHover={{ scale: 1.02, y: -5 }}
                    transition={{ type: "spring", stiffness: 300 }}
                    className="h-full p-8 bg-white/60 dark:bg-gray-800/60 backdrop-blur-sm rounded-2xl shadow-lg ring-1 ring-gray-900/5 dark:ring-gray-700 flex flex-col items-center text-center relative z-10"
                  >
                    <div className={`flex items-center justify-center w-16 h-16 mb-6 ${feature.iconBg} rounded-2xl shadow-sm group-hover:shadow-md transition-shadow duration-300`}>
                      <feature.icon className="w-8 h-8 text-gray-900 dark:text-white" />
                    </div>
                    <h3 className="font-['Domine'] mb-4 text-2xl font-bold text-gray-900 dark:text-white group-hover:text-primary-600 dark:group-hover:text-primary-400 transition-colors duration-300">
                      {feature.title}
                    </h3>
                    <p className="font-['Nunito'] text-gray-600 dark:text-gray-300 text-base leading-relaxed max-w-sm">
                      {feature.description}
                    </p>
                  </motion.div>
                  
                  {/* Updated decorative elements */}
                  <div 
                    className={`absolute -z-10 -inset-x-4 -inset-y-4 bg-gradient-to-r ${feature.gradient} rounded-3xl opacity-0 group-hover:opacity-100 transition-opacity duration-300 blur-xl`} 
                  />
                  <div 
                    className={`absolute -z-10 -inset-x-4 -inset-y-4 bg-gradient-to-r ${feature.gradient} rounded-3xl opacity-0 animate-in fade-in duration-500`} 
                    style={{ '--tw-bg-opacity': '0.15' }}
                  />
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Features Detail Section */}
        <div className="overflow-hidden bg-white dark:bg-gray-900 py-16 sm:py-24">
          {/* Centered headers */}
          <div className="text-center max-w-7xl mx-auto px-6 lg:px-8 mb-16">

            <h2 className="font-['Domine'] mt-4 text-pretty text-4xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-5xl">
              {t('features.title')}
            </h2>
          </div>

          {/* Main content container */}
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-center">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="flex items-center justify-center overflow-visible"
              >
                <DocumentComparison />
              </motion.div>

              <div className="lg:pl-8 lg:pt-4">
                <div className="lg:max-w-lg mb-8 text-center">
                  <h3 className="font-['Domine'] text-3xl sm:text-4xl font-bold text-gray-900 dark:text-white">
                    {t('features.subtitle')}
                    <span className="block mt-2 text-transparent bg-clip-text bg-gradient-to-r from-blue-600 via-primary-500 to-indigo-500 dark:from-blue-400 dark:via-primary-400 dark:to-indigo-400">
                      {t('features.tagline')}
                    </span>
                  </h3>
                </div>
                <div className="lg:max-w-lg">
                  <dl className="grid grid-cols-1 gap-8">
                    {[
                      {
                        name: t('features.items.fraud.title'),
                        description: t('features.items.fraud.description'),
                        icon: DocumentMagnifyingGlassIcon,
                      },
                      {
                        name: t('features.items.verify.title'),
                        description: t('features.items.verify.description'),
                        icon: DocumentCheckIcon,
                      },
                      {
                        name: t('features.items.implement.title'),
                        description: t('features.items.implement.description'),
                        icon: CodeBracketIcon,
                      },
                    ].map((feature) => (
                      <motion.div 
                        key={feature.name}
                        className="relative p-6 rounded-2xl bg-gradient-to-br from-primary-500/5 to-primary-500/10 dark:from-primary-500/5 dark:to-primary-500/10 backdrop-blur-sm border border-primary-500/10 dark:border-primary-400/10"
                        whileHover={{ scale: 1.02 }}
                        transition={{ type: "spring", stiffness: 300 }}
                      >
                        <dt className="flex items-center gap-4 mb-4">
                          <div className="rounded-xl bg-primary-100 dark:bg-primary-900/30 p-3">
                            <feature.icon 
                              className="h-6 w-6 text-primary-600 dark:text-primary-400"
                              aria-hidden="true" 
                            />
                          </div>
                          <span className="font-['Nunito'] font-semibold text-gray-900 dark:text-white text-xl">
                            {feature.name}
                          </span>
                        </dt>
                        <dd className="font-['Nunito'] text-base text-gray-600 dark:text-gray-300 leading-relaxed">
                          {feature.description}
                        </dd>
                      </motion.div>
                    ))}
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Pricing Section */}
        <div id="pricing" className="relative isolate bg-white dark:bg-gray-900 py-16 scroll-mt-20">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            {/* Background gradient decoration */}
            <div aria-hidden="true" className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl">
              <div
                className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-primary-400 to-primary-600 opacity-20 dark:opacity-20"
                style={{
                  clipPath:
                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                }}
              />
            </div>

            {/* Pricing content */}
            <div className="mx-auto max-w-4xl text-center">
              <h2 className="font-['Domine'] mt-2 text-balance text-4xl font-semibold tracking-tight text-gray-900 dark:text-white sm:text-5xl">
                {t('pricing.title')}
              </h2>
            </div>
            
            <div className="mx-auto mt-16 grid max-w-lg grid-cols-1 items-stretch gap-y-8 sm:mt-20 lg:max-w-none lg:grid-cols-3 lg:gap-x-8">
              {tiers.map((tier, tierIdx) => {
                const tierData = getTierData(tier, t);
                return (
                  <motion.div
                    key={tier.id}
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, margin: "-10px" }}
                    transition={{ 
                      delay: tierIdx * 0.2,  
                      duration: 0.8,         
                      ease: "easeOut"        
                    }}
                    className={classNames(
                      tier.featured 
                        ? 'relative bg-gray-100 dark:bg-gray-800 shadow-2xl sm:scale-105 z-10'
                        : 'bg-white dark:bg-gray-900 lg:mx-0',
                      'rounded-3xl p-8 ring-1 ring-gray-900/10 dark:ring-gray-700 sm:p-10 h-full flex flex-col'
                    )}
                  >
                    <div className="flex items-center justify-between">
                      <h3 className="font-['Domine'] text-primary-600 dark:text-primary-400 text-2xl font-bold">
                        {tierData.name}
                      </h3>
                      {tier.featured && (
                        <span className="inline-flex items-center rounded-md bg-green-50 dark:bg-green-900/30 px-2 py-1 text-xs font-medium text-green-700 dark:text-green-400 ring-1 ring-inset ring-green-600/20 dark:ring-green-500/30">
                          Recommended
                        </span>
                      )}
                    </div>
                    
                    <p className="mt-4 flex items-baseline gap-x-2">
                      <span className="font-['Domine'] text-gray-900 dark:text-white text-4xl font-semibold tracking-tight">
                        {tierData.priceMonthly}
                      </span>
                      <span className="text-gray-500 dark:text-gray-400 text-base">/ {t('pricing.month')}</span>
                    </p>
                    
                    <p className="font-['Nunito'] text-base text-gray-600 dark:text-gray-300 mt-6 text-base">
                      {tierData.description}
                    </p>
                    
                    <ul className="mt-8 space-y-3 text-base text-gray-600 dark:text-gray-300 flex-grow">
                      {tierData.features.map((feature) => (
                        <li key={feature} className="flex gap-x-3">
                          <CheckIcon className="text-primary-600 dark:text-primary-400 h-6 w-5 flex-none" aria-hidden="true" />
                          {feature}
                        </li>
                      ))}
                    </ul>
                    
                    <motion.a
                      href={tier.href}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      className={classNames(
                        tier.featured
                          ? 'bg-primary-500 text-white shadow-sm hover:bg-primary-400 focus-visible:outline-primary-500'
                          : 'text-primary-600 ring-1 ring-inset ring-primary-200 hover:ring-primary-300 focus-visible:outline-primary-600 dark:text-primary-400 dark:ring-primary-800 dark:hover:ring-primary-700',
                        'mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
                      )}
                    >
                      {tier.translationKey === 'corporate' ? t('pricing.tiers.corporate.cta') : t('pricing.tiers.basic.cta')}
                    </motion.a>
                  </motion.div>
                );
              })}
            </div>
          </div>
        </div>

        {/* FAQ Section */}
        <section className="relative bg-white dark:bg-gray-900 py-16">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            {/* Background gradient decoration */}
            <div aria-hidden="true" className="absolute inset-0 -z-10 mx-0 max-w-full overflow-hidden">
              <div className="absolute left-1/2 top-0 h-[24rem] w-[84rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0">
                <div className="absolute inset-0 bg-gradient-to-r from-primary-400/20 via-primary-300/10 to-primary-400/20 dark:from-primary-700/30 dark:via-primary-600/20 dark:to-primary-700/30 blur-3xl" />
              </div>
            </div>

            <div className="mx-auto max-w-4xl text-center">

              <h2 className="mt-2 font-['Domine'] text-4xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-5xl">
                {t('faq.title')}
              </h2>

            </div>

            <div className="mx-auto mt-16 max-w-7xl">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 md:gap-x-8">
                {/* Left Column */}
                <div className="space-y-4">
                  {getFAQs(t).slice(0, Math.ceil(getFAQs(t).length / 2)).map((faq, index) => (
                    <Disclosure as="div" key={index}>
                      {({ open }) => (
                        <motion.div
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ delay: index * 0.1 }}
                          className="overflow-hidden bg-blue-50 dark:bg-gray-800/50 rounded-lg"
                        >
                          <Disclosure.Button 
                            className={`flex w-full justify-between px-6 py-4 text-left transition-colors duration-200 
                              ${open 
                                ? 'bg-blue-100 dark:bg-gray-800/80' 
                                : 'hover:bg-blue-100 dark:hover:bg-gray-800/80'
                              }`}
                          >
                            <span className="font-['Nunito'] font-medium text-gray-900 dark:text-white pr-4">
                              {faq.question}
                            </span>
                            <motion.div
                              initial={false}
                              animate={{ rotate: open ? 180 : 0 }}
                              transition={{ duration: 0.3, ease: "easeInOut" }}
                            >
                              <ChevronUpIcon className="h-5 w-5 text-primary-500 flex-shrink-0" />
                            </motion.div>
                          </Disclosure.Button>

                          <AnimatePresence initial={false}>
                            {open && (
                              <motion.div
                                initial={{ height: 0, opacity: 0 }}
                                animate={{ 
                                  height: "auto",
                                  opacity: 1,
                                  transition: {
                                    height: {
                                      duration: 0.3,
                                      ease: "easeOut"
                                    },
                                    opacity: {
                                      duration: 0.2,
                                      delay: 0.1
                                    }
                                  }
                                }}
                                exit={{ 
                                  height: 0,
                                  opacity: 0,
                                  transition: {
                                    height: {
                                      duration: 0.3,
                                      ease: "easeIn"
                                    },
                                    opacity: {
                                      duration: 0.2
                                    }
                                  }
                                }}
                              >
                                <Disclosure.Panel static>
                                  <div className="px-6 py-4 font-['Nunito'] text-base text-gray-600 dark:text-gray-300 border-t border-blue-200 dark:border-gray-700">
                                    {faq.answer}
                                  </div>
                                </Disclosure.Panel>
                              </motion.div>
                            )}
                          </AnimatePresence>
                        </motion.div>
                      )}
                    </Disclosure>
                  ))}
                </div>

                {/* Right Column */}
                <div className="space-y-4 md:mt-0 mt-4">
                  {getFAQs(t).slice(Math.ceil(getFAQs(t).length / 2)).map((faq, index) => (
                    <Disclosure as="div" key={index}>
                      {({ open }) => (
                        <motion.div
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ delay: (index + Math.ceil(getFAQs(t).length / 2)) * 0.1 }}
                          className="overflow-hidden bg-blue-50 dark:bg-gray-800/50 rounded-lg"
                        >
                          <Disclosure.Button 
                            className={`flex w-full justify-between px-6 py-4 text-left transition-colors duration-200 
                              ${open 
                                ? 'bg-blue-100 dark:bg-gray-800/80' 
                                : 'hover:bg-blue-100 dark:hover:bg-gray-800/80'
                              }`}
                          >
                            <span className="font-['Nunito'] font-medium text-gray-900 dark:text-white pr-4">
                              {faq.question}
                            </span>
                            <motion.div
                              initial={false}
                              animate={{ rotate: open ? 180 : 0 }}
                              transition={{ duration: 0.3, ease: "easeInOut" }}
                            >
                              <ChevronUpIcon className="h-5 w-5 text-primary-500 flex-shrink-0" />
                            </motion.div>
                          </Disclosure.Button>

                          <AnimatePresence initial={false}>
                            {open && (
                              <motion.div
                                initial={{ height: 0, opacity: 0 }}
                                animate={{ 
                                  height: "auto",
                                  opacity: 1,
                                  transition: {
                                    height: {
                                      duration: 0.3,
                                      ease: "easeOut"
                                    },
                                    opacity: {
                                      duration: 0.2,
                                      delay: 0.1
                                    }
                                  }
                                }}
                                exit={{ 
                                  height: 0,
                                  opacity: 0,
                                  transition: {
                                    height: {
                                      duration: 0.3,
                                      ease: "easeIn"
                                    },
                                    opacity: {
                                      duration: 0.2
                                    }
                                  }
                                }}
                              >
                                <Disclosure.Panel static>
                                  <div className="px-6 py-4 font-['Nunito'] text-base text-gray-600 dark:text-gray-300 border-t border-blue-200 dark:border-gray-700">
                                    {faq.answer}
                                  </div>
                                </Disclosure.Panel>
                              </motion.div>
                            )}
                          </AnimatePresence>
                        </motion.div>
                      )}
                    </Disclosure>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Replace CTA section with component */}
        <CTA />
      </div>
      
      {alert && (
        <ErrorModal
          title={alert.title}
          message={alert.message}
          onClose={() => setAlert(null)}
        />
      )}
    </>
  );
}

export default Home;